.hero-banner-wrapper {
    position: absolute;
    left: 0;
    top: 20%;
    // left: 50%;
    // transform: translateX(-50%);
    width: 100%;

    h2 {
        color: $white-color;
    }

    .icon {
        width: 24px;
        height: 24px;
    }
}

.hero-banner-section {
    position: relative;
    height: calc(100vh - 322px);

    @include tablet-small {
        height: calc(100vh - 600px);
    }

    @include mobile {
        height: calc(100vh - 210px);
    }

    .slick-slider {
        outline: none !important;
        font-size: 0;

        & * {
            outline: none !important;
        }
    }

    .slick-dots {
        // display: flex !important;
        // justify-content: center;
        bottom: auto;
        width: auto;
        margin: 0;
        list-style: none;
        text-align: center;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);

        li {
            display: inline-block;
            width: 20px;
            height: 4px;
            background-color: $white-color;
            margin-right: 8px;
            opacity: 0.6;
            transition: all 0.2s linear;

            &:last-child {
                margin-right: 0;
            }

            &.slick-active {
                width: 40px;
                opacity: 1;
            }
        }

        button {
            width: 100%;
            height: 100%;

            &::before {
                display: none;
            }
        }
    }

    @include tablet-small {
        .slick-dots {
            bottom: -10px;
            width: auto;
            margin: 0;
            list-style: none;
            text-align: center;
            right: 0;
            top: auto;
            left: 50%;
            transform: translate(-50%, -50%) rotate(0deg);
        }
    }
}


.hero-banner-img {
    img {
        // height: 600px;
        height: calc(100vh - 322px);
        max-height: calc(100vh - 322px);
        object-fit: cover;
        width: 100%;

        @include tablet-small {
            max-height: calc(100vh - 600px);
            height: calc(100vh - 600px);
        }

        @include mobile {
            height: calc(100vh - 210px);
            max-height: calc(100vh - 210px);
        }
    }
}

.search-input {
    position: relative;

    @include mobile {
        width: 100%;
    }

    .icon-search {
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
    }

    .icon-camera {
        width: 20px;
        height: 20px;
        padding-right: 14px;
        margin-right: 15px;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &::after {
            content: "";
            width: 1px;
            height: 28px;
            display: block;
            background-color: $black-color;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: auto;
            right: -14px;
            opacity: 0.3;

            @include mobile {
                display: none;
            }
        }
    }
}

.search-block-wrapper {
    position: relative;
    background-color: $white-color;
    border-radius: 6px;
    max-width: 701px;
    margin-left: auto;
    margin-right: auto;

    @include mobile {
        margin: 0 15px;
    }

    .search-input {
        input[type="text"] {
            padding: 19px 14px 19px 55px;
            font-size: 18px;
            line-height: 22px;
            outline: none;
            border-radius: 6px;
            width: 100%;
        }
    }

    select {
        padding: 20px 30px 20px 0;
        outline: none;
        appearance: none;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_478_2717)'%3E%3Cpath d='M11.9997 13.172L16.9497 8.22205L18.3637 9.63605L11.9997 16L5.63574 9.63605L7.04974 8.22205L11.9997 13.172Z' fill='%23F8A11F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_478_2717'%3E%3Crect width='24' height='24' fill='white' transform='translate(-0.000488281)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: right center;
        font-size: 18px;
        line-height: 22px;
    }

    >.button {
        border-radius: 0;
        padding: 20px 33px;
        line-height: 20px;
        margin-left: 20px;
        margin-left: auto;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        &:hover {
            background-color: $primary-color;
            border-color: $primary-color;
        }
    }
}

.search-block-wrapper {
    .icon-location {
        width: 20px;
        height: 20px;
        margin-left: 14px;
        margin-right: 3px;
    }
}

.hero-banner-arrow {
    .slick-arrow {
        color: #000;
        font-size: 0;
        line-height: 1;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: 1px solid #ffffff;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;

        &.slick-disabled {
            // background-color: $secondary-color;
            cursor: not-allowed;
            opacity: 0.8;
        }
    }

    .slick-next {
        top: 50%;
        right: 23px;
        left: auto;
        transform: rotate(90deg) translateX(60px);

        &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='16' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.12061 1.83603L8.14785 7.86328L2.12061 13.8905' stroke='white' stroke-width='3.62942'/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 11px;
            height: 16px;
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }

    .slick-prev {
        top: 50%;
        right: 23px;
        left: auto;
        transform: rotate(90deg) translateX(-85px);

        &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width=%2711%27 height=%2716%27 viewBox=%270 0 11 16%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M8.87549 13.89L2.84824 7.86271L8.87549 1.83546%27 stroke=%27white%27 stroke-width=%273.62942%27/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 11px;
            height: 16px;
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }

    .slick-next:before,
    .slick-prev:before {
        color: #000;
    }
}