.blog-section {
    &.with-bg {
        background-color: $gray-color-100;
        padding: 70px 0;

        @include mobile {
            padding: 32px 0;
        }
    }
}

.blog-wrapper {
    .action-block {
        @include mobile {
            margin-left: unset;
            margin-top: 15px;
        }
    }
}

.blog-tagline {
    color: $body-font-color;
    font-size: 16px;
    opacity: 0.8;
}

.blogs-title {
    color: #1E1F4B;
    font-size: 18px;

    a {
        color: #1E1F4B;

        &:hover {
            color: $primary-color;
        }
    }
}

.blog-des {
    display: none;
    color: $body-font-color;
    font-size: 15px;
    opacity: 0.8;
    line-height: 30px;
}

.blog-items-list {
    .blog-item {

        // flex: 0 0 auto;
        // width: 25%;
        @include mobile {
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:first-child {
            .blog-image {
                width: 100%;

                img {
                    height: 100%;
                    min-height: 519px;
                    max-height: 519px;

                    @include tablet-small {
                        min-height: 280px;
                        max-height: 280px;
                    }

                    @include mobile {
                        min-height: 218px;
                        max-height: 218px;
                    }
                }
            }

            .blogs-title {
                font-size: 26px;
            }

            .blog-des {
                display: block;
            }
        }
    }
}

.blog-image {
    width: 100%;
    height: auto;

    img {
        border-radius: 30px;
        width: 100%;
        height: 100%;
        min-height: 218px;
        max-height: 218px;
        object-fit: cover;

        @include tablet-small {
            min-height: 130px;
            max-height: 130px;
        }

        @include mobile {
            min-height: 218px;
            max-height: 218px;
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @include mobile {
        display: block;
    }

    &-item1 {
        grid-area: 1 / 1 / 3 / 3;
    }

    &-item2 {
        grid-area: 1 / 3 / 2 / 4;
    }

    &-item3 {
        grid-area: 1 / 4 / 2 / 5;
    }

    &-item4 {
        grid-area: 2 / 3 / 3 / 4;
    }

    &-item5 {
        grid-area: 2 / 4 / 3 / 5;
    }
}

/*--- [ Blog Page ] ---*/
.blog-page {
    .blog-items {
        @include display-flex(flex);
        @include flex-wrap(wrap);
        @include row;

        .blog-item {
            width: calc(100% / 4);
            @include col;
            margin-bottom: 30px;

            @include vw {
                margin-bottom: get-vw(60px);
            }

            @include tablet-small {
                width: calc(100% / 3);
            }

            @include mobile {
                width: 100%;
            }
        }
    }
}

/*--- [ Blog Detail Page ] ---*/
.page-heading--image {
    .bg-content {
        position: relative;
        text-align: center;
        color: $white-color;

        p {
            font-size: 22px;

            @include vw {
                font-size: get-vw(44px);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        h2 {
            margin-bottom: 0;

            +p {
                margin-top: 15px;

                @include vw {
                    margin-top: get-vw(30px);
                }
            }
        }
    }
}

.blog-details-main {
    .blog-thumb {
        float: left;
        margin-right: 30px;
        margin-bottom: 25px;
        width: 554px;
        height: 396px;

        @include vw {
            margin-right: get-vw(60px);
            margin-bottom: get-vw(50px);
            width: get-vw(1108px);
            height: get-vw(792px);
        }

        @include tablet-small {
            width: 50%;
            height: 250px;
        }

        @include mobile {
            width: 100%;
        }

        @include mobile {
            margin-right: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    p {
        font-size: 16px;
        line-height: 32px;

        @include vw {
            font-size: get-vw(32px);
            line-height: get-vw(64px);
        }
    }
}