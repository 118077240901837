.navbar-nav {
    .nav-item {
        margin-right: 30px;
        padding-top: 35px;
        padding-bottom: 36px;
        position: relative;

        @include vw {
            margin-right: get-vw(60px);
            padding-top: get-vw(70px);
            padding-bottom: get-vw(72px);
        }

        &:last-child {
            margin-right: 0;
        }

        &.active {
            &:after {
                content: "";
                width: 100%;
                height: 7px;
                background-color: $secondary-color;
                display: block;
                position: absolute;
                bottom: 0;

                @include vw {
                    height: get-vw(14px);
                }
            }

            .nav-link {
                color: $secondary-color;
            }
        }

        .nav-link {
            padding: 0;
            font-size: $body-font-size;
            line-height: 19px;

            @include vw {
                line-height: get-vw(38px);
                font-size: get-vw(32px);
            }

            &:hover {
                color: $secondary-color;
            }
        }
    }
}

.header-right-side-wrapper {
    .icon-wrapper {
        @include mobile {
            margin-right: 0;
        }
    }

    .user-login {
        @include mobile {
            display: none;
        }

        a {
            color: $black-color;

            &:hover {
                color: $secondary-color;
            }
        }

        .icon {
            width: 35px;
            height: 35px;

            img {
                min-width: 35px;
                width: 35px;
                height: 35px;

                @include vw {
                    min-width: get-vw(70px);
                    width: get-vw(70px);
                    height: get-vw(70px);
                }
            }

            @include vw {
                width: get-vw(70px);
                height: get-vw(70px);
            }
        }
    }

    .icon-wrapper {
        .icon {
            width: 56px;
            height: 44px;
            background-color: #615EF01A;
            border-radius: 8px;

            @include vw {
                width: get-vw(112px);
                height: get-vw(88px);
                border-radius: get-vw(16px);
            }

            @include mobile {
                width: 35px;
                height: 35px;
            }

            svg {
                fill: $primary-color;
                width: 24px;
                height: 24px;

                @include vw {
                    width: get-vw(48px);
                    height: get-vw(48px);
                }

                @include mobile {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .icon-link {
            &:hover {
                .icon {
                    background-color: $secondary-color;
                }

                svg {
                    fill: $white-color;
                }
            }
        }
    }

}

// BOOTStRAP RESET STYLING
.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
}

.logo-wrapper {

    svg {
        height: 46px;
        width: 162px;

        @include vw {
            width: get-vw(324px);
            height: get-vw(92px);
        }
    }
}

// Header Responsive
@include tablet-small {
    .logo-wrapper {
        width: 100%;
        margin-left: 30px;
    }

    .main-header.header {
        padding: 20px 12px;
    }

    .nav-responsive {
        position: fixed;
        /* position: absolute; */
        left: 0;
        top: 86px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 99999;
        transition: none;
        padding: 40px 30px;
        display: none;

        &.show {
            display: block;
        }

        &.collapse {
            visibility: visible;
        }

        .navbar-nav .nav-item {
            margin-right: 0;
            padding-top: 0;
            padding-bottom: 36px;
            text-align: left;
        }

        .navbar-nav .nav-item.active:after {
            display: none;
        }
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }
}