// LEFT PANEL
.category-panel-section {

    .left-panel-wrapper,
    .right-panel-wrapper {
        max-height: 768px;
        overflow: auto;

        /* width */
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 15px;
            border: 1px solid #e9e9e9;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $primary-color;
            border-radius: 15px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $secondary-color;
        }
    }
}

.left-panel-nav {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;

    @include mobile {
        display: flex;
        width: 100%;
        overflow: auto;
        margin-bottom: 15px;
    }

    .category-item {
        border-radius: 10px;

        @include mobile {
            min-width: fit-content;
        }

        &.active,
        &:hover {
            background-color: $gray-color-100;

            .category-link {
                color: $primary-color;
            }
        }

        .icon {
            min-width: 56px;

            @include tablet-small {
                min-width: 30px;
                margin-right: 15px;
            }

            img {
                width: 56px;
                height: 56px;
                object-fit: cover;

                @include tablet-small {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .category-link {
        padding: 20px 42px 20px 27px;
        text-align: left;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_470_2901)'%3E%3Cpath d='M13.1722 12L8.22217 7.05001L9.63617 5.63601L16.0002 12L9.63617 18.364L8.22217 16.95L13.1722 12Z' fill='%23222662'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_470_2901'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 24) rotate(-90)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 22px center;
        color: $black-color;

        @include tablet-small {
            padding: 20px 35px 20px 15px;
            background-position: right 15px center;
            font-size: 14px;
        }
    }
}

// RIGHT PANEL
.category-panel-section {
    .right-panel-wrapper {
        background-color: $gray-color-100;
        padding: 24px 28px;
        border-radius: 10px;
        height: 100%;

        .category-tabs {
            padding-left: 0;
            margin-left: 0;
            margin-bottom: 17px;

            @include mobile {
                overflow-x: scroll;
            }
        }

        .category-tab-item {
            margin-right: 10px;
            padding: 5px 16px;
            background-color: #EBEBEB;
            border-radius: 8px;

            @include mobile {
                min-width: max-content;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active {
                background-color: $primary-color;

                .category-tab-link {
                    color: $white-color;
                }
            }
        }

        .category-tab-link {
            color: $primary-color;

            @include tablet-small {
                font-size: 14px;
            }
        }
    }
}


.services-link-wrapper {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    .heading-wrapper {
        text-align: left;
        margin-bottom: 16px;

        .heading {
            font-weight: 700;
        }
    }

    ul {
        margin-left: 0;
        margin-bottom: 0;
        padding-left: 0;
        column-count: 4;

        @include tablet-small {
            column-count: 3;
        }

        @include mobile {
            column-count: 1;
        }
    }

    .services-link-item {
        text-align: left;
        margin-bottom: 10px;
        line-height: 1.58;

        &.active,
        &:hover {
            .services-link {
                color: $primary-color;
                text-decoration: underline;
                font-weight: 500;
            }
        }
    }

    .services-link {
        color: $black-color;
        font-weight: 400;

        @include tablet-small {
            font-size: 14px;
        }
    }
}